.company-logo {
  background-color: #f5f6fa;
  padding: 17px;
  color: #3e5eff;

  img {
    width: 80px;
  }
}

.p-panelmenu-icon {
  order: 1; // to be the first item on right side.
}

.p-menuitem-text {
  flex-grow: 1; // to fill the whole space and push the icon to the end
}

.sidebar {
  background-color: #f5f6fa;
  height: 100%;
  position: fixed;
  z-index: 999;
  overflow-y: scroll;

  .ui-menuitem-text {
    font-size: 14px;
  }

  .ui-widget-content {
    background-color: $sidebarBgColor;
    border: 0 none;

    .ui-menuitem-link {
      border-left: 3px solid $sidebarBgColor;
      color: $sidebarMenuTextColor;
      height: 40px;
      padding: 8px 4px 3px;

      &:hover {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        color: $sidebarMenuHoverTextColor;
      }

      &:active {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        border-left: 0 solid $defaultThemeColor;
        color: $sidebarMenuHoverTextColor;
        padding: 8px 4px 3px;
      }

      .ui-menuitem-text {
        font-size: 12px;
      }
    }

    .ui-menuitem {
      &:not(.ui-state-active) {
        &:hover {
          background-color: $sideBarHoverBgColor;
          border-color: $sideBarHoverBgColor;
          color: $sidebarMenuHoverTextColor;
        }

        &:active {
          background-color: $sideBarHoverBgColor;
          border-color: $sideBarHoverBgColor;
          border-left: 3px solid $defaultThemeColor;
          color: $sidebarMenuHoverTextColor;
        }
      }

      .ui-state-active {
        background: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        border-left: 3px solid $defaultThemeColor;

        .ui-menuitem-text {
          color: $sidebarMenuHoverTextColor;
          font-size: 12px;
        }
      }
    }
  }

  .ui-panelmenu {
    .fa {
      color: $sidebarIconColor;
      font-size: 20px;
      margin-right: 12px;
    }

    .ui-panelmenu-header {
      a {
        border-left: 9px solid transparent;
        color: $sidebarMenuTextColor;
        padding: 20px 0 15px 16px;
      }

      &:hover {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
      }

      &:active {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;

        a {
          color: $white;
        }
      }

      .ui-state-active {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        border-left: 9px solid $sidebarActiveColor;
        color: $sidebarActiveColor;
      }

      .ui-panelmenu-headerlink-hasicon {
        &.ui-state-active {
          background: $sideBarHoverBgColor;
          border-color: $sideBarHoverBgColor;
          box-shadow: inset 3 0 0 0 rgba(236, 29, 37, 1);
          color: $white;
        }

        &:hover {
          background-color: $sideBarHoverBgColor;
          border-top: 0;
          box-shadow: inset 3px 0 0 0 rgba(236, 29, 37, 1);
        }
      }
    }
  }

  a {
    color: $sidebarMenuHoverTextColor;
  }

  .ui-state-default {
    background: $sidebarBgColor;
    border: 0 solid $sidebarBgColor;
    color: $sidebarMenuTextColor;
  }

  .fa-caret-right,
  .fa-caret-down {
    &::before {
      content: '';
    }
  }

  .ui-panelmenu-icon {
    &.fa {
      // display: none;
      font-size: 18px;
    }
  }
}

@media screen and (min-width: 1600px) {
  .left-panel {
    width: 15%;
  }

  .right-panel {
    width: 85%;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .left-panel {
    width: 20%;
  }

  .right-panel {
    width: 80%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .left-panel {
    width: 22%;
  }

  .right-panel {
    width: 78%;
  }
}

.p-dialog.p-confirm-dialog {
  .p-confirm-dialog-icon {
    font-size: 2rem;
    align-content: center;
    display: flex;
  }
}

.confirmDialog-cancel-btn {
  color: #000;
}