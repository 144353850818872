.mi-grid {
  background: $white;
  padding: 20px 10px 0 18px;
  border-radius: 5px;
}

.inp {
  width: 340px;
  height: 40px;
}

// .search-and-export-data-panel {
//   .p-input-icon-right {
//     .pi::before {
//       font-size: 16px;
//     }
//   }
// }
.no-records {
  font-family: Roboto;
  font-size: 17px;
  font-weight: 600;
  height: 60px;
  margin: 30px;
  text-align: center;
  width: 100%;

  .center-div {
    position: absolute;
    text-align: center;
    width: 97%;
  }
}

.grid-menu {
  background: $white;
  border: 2px;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
  display: none;
  font-family: Roboto;
  margin-left: -158px;
  margin-top: -10px;
  overflow: hidden;
  position: absolute;
  width: 150px;
  z-index: 99999;

  .grid-menu-item {
    div {
      color: #000;
      padding: 10px;

      &:hover {
        background: $btnBgColor;
        color: #fff;
      }
    }
  }
}

.action-icon {
  background: url('/assets/images/gridGroupIcon.png') 6px 0 no-repeat;
  cursor: pointer;
  float: right;
  height: 25px;
  margin: 0;
  padding: 10px;
}

.search-and-export-data-panel {
  input {
    &.grid-search-bar {
      background-color: $white;
      border: solid 1px #000;
      border-radius: 2px;
      font-family: Roboto;
      font-size: 15px;
      height: 36px;
      opacity: .45;
      text-indent: 20px;
      width: 250px;

      &.top {
        margin-top: 0;
      }

      &.bottom {
        margin-top: 15px;
      }
    }
  }

  label {
    &.input-icon {
      left: -22px;
      position: relative;
    }
  }

  .export-button {
    float: right;

    &.top {
      margin-bottom: 15px;
    }

    &.bottom {
      margin-top: 15px;
    }

    .header-action-btn {
      background-color: $inputFieldBorderColor;
      border: 1px solid $defaultThemeColor;
      border-radius: 2px;
      color: $defaultThemeColor;
      cursor: pointer;
      float: right;
      font-family: Roboto;
      font-size: 18px;
      line-height: 22px;
      margin: 0 2px;
      padding: 5px 6px;
      text-align: center;
      vertical-align: middle;

      &:hover {
        border: 1px solid $defaultThemeColor;
      }
    }
  }
}

.ui-table {
  .ui-table-tbody {
    tr {
      &.ui-state-highlight {
        background-color: #fff;
        color: #444;
      }
    }
  }
}

p-table {
  .ui-table {
    font-family: Roboto;

    div {
      &.ui-table-wrapper {
        border-bottom: 1px solid #ddd;
      }
    }

    tfoot {
      &.ui-table-tfoot {
        td {
          padding: 0;
        }
      }
    }

    .ui-table-sort-icon {
      display: none;
    }

    .ui-table-thead {
      tr {
        th {
          border-bottom: 1px $contentBorderColor;
          color: $black;
          font-family: Roboto;
          font-size: 13px;
          font-weight: 600;
          padding: 32px 15px 9px;
          text-align: left;
          text-transform: uppercase;
        }
      }
    }

    .ui-sortable-column {
      &:not(.ui-state-highlight) {
        &:hover {
          background: rgba(255, 255, 255, .2);
          color: $black;
        }
      }

      &.ui-state-highlight {
        background-color: $white;
        color: $black;
      }
    }

    .ui-table-tbody {
      tr {
        td {
          border-bottom: 0;
          border-left: 0;
          border-right: 0;
          color: #7d7d7d;
          font-family: Roboto;
          font-size: 16px;
          letter-spacing: $fontNormal;
          line-height: $fontNormal;
          padding: 20px 15px;

          .prime-btn {
            &.ui-state-default {
              font-size: 13px;
              font-weight: 600;
              padding: 8px 23px;
            }
          }

          .active {
            color: $activeStatus;
            width: 50px;
          }

          .inactive {
            color: $inactiveStatus;
            width: 64px;
          }

          .ended {
            color: $endedStatus;
            width: 64px;
          }
        }
      }
    }

    &.ui-table-hoverable-rows {
      .ui-table-tbody {
        tr {
          &:not(.ui-state-highlight) {
            &:not(.ui-contextmenu-selected) {
              &:hover {
                background: #f5f5f5;
                color: $black;
              }
            }
          }
        }
      }
    }
  }
}

.colFname {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #3e5eff;
  cursor: pointer;
}

.eclipse {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #212736;
  pointer-events: none;
}

.eclipse-none {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #212736;
  pointer-events: none;
  white-space: break-spaces;
}

.eclipse-tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #212736;
}

.break-word {
  word-break: break-all;
}

.eclipse-pointer {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #212736;
}

.uploadFileCol {
  pointer-events: none;
}

.statusCol {
  color: green;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: 7px;
  margin-left: 0;

  &.pi::before {
    padding-left: 2px;
  }
}

.p-datatable table .pi::before {
  font-size: 7px;
}

.gridHead {
  .pri-btn {
    margin-top: 0;
    padding-left: 5px;
  }
}

.grid .gridHead {
  padding-left: 21px;
}

.searchbar input {
  border-radius: 5px;
}

.green-class {
  color: #61aa14;
  pointer-events: none;
}

.red-class {
  color: #e60000;
  pointer-events: none;
}

.black-class {
  color: #313353;
  pointer-events: none;
}

.orange-class {
  color: #fe7b2d;
  pointer-events: none;
}

.yellow-class {
  color: #edbb25;
  pointer-events: none;
}

.blue-class {
  color: #1449aa;
  pointer-events: none;
}

.brown-class {
  color: #c66060;
  pointer-events: none;
}

.eclipse-product {
  pointer-events: none;
}

.p-datatable .p-datatable-tfoot>tr>td {
  background: #fff;
  display: contents;
}

.total-record {
  font-size: 17px;
  font-weight: 500;
  color: #404566;
  position: relative;
  bottom: 2px;
}

.screen-title {
  padding-left: 1.4rem;
}

#iconSize i::before {
  font-size: 13px;
}

.marginAuto {
  margin: auto;
}

.calenderClass {
  padding: 0;
  margin: -5px;
}

.searchBarMargin {
  margin-top: 15px;
}


.colId {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 6.5rem;
}

.colId-combo-offer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-number {
  text-align: right !important;
  padding-right: 1.2rem !important;
}

.text-number-no-sort {
  text-align: right !important;
  padding-right: 2.5rem !important;
}

.p-datatable .p-datatable-tbody>tr>.text-number-order {
  text-align: right;
  padding-right: 2.5rem;
}

.p-datatable .p-datatable-thead>tr>.headClass {
  text-align: right;
  padding-right: 2.5rem;
}

.relode-btn {
  color: #404566 !important;
}

.relode-btn .pi::before {
  font-size: 20px;
}

.gridHead {
  .justify-content-evenly {
    @media screen and (min-width: 1400px) and (max-width: 1599px) {
      .dates-wrapper {
        display: contents;

        .p-calendar-clearable {
          display: inline-block;
          width: 23%;

          &:last-child {
            margin-right: .5rem;
          }
        }
      }
    }

    @media screen and (min-width: 1270px) and (max-width: 1400px) {
      .dates-wrapper {
        display: contents;

        .p-calendar-clearable {
          display: inline-block;
          width: 23%;

          &:last-child {
            margin-right: .5rem;
          }
        }
      }
    }

    @media screen and (min-width: 1024px) and (max-width: 1270px) {
      .dates-wrapper {
        display: contents;

        .p-calendar-clearable {
          display: inline-block;
          width: 20%;

          &:last-child {
            margin-right: .5rem;
          }
        }
      }

      .statusDropdown {
        width: 152px;
      }
    }
  }

  .justify-content-start {
    @media screen and (min-width: 1270px) and (max-width: 1400px) {
      .searchbar {
        input {
          min-width: 260px;
        }
      }
    }

    @media screen and (min-width: 1024px) and (max-width: 1270px) {
      .searchbar {
        margin-right: 15px;

        input {
          min-width: 146px;
        }
      }
    }
  }
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.eclipse-disabled {
  pointer-events: none;
  cursor: default;
}

.refreshClass {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.reloadLabel {
  font-family: Roboto;
  font-size: 18px;
  cursor: pointer;
  line-height: 1.27;
  letter-spacing: normal;
  color: #313353;
}

.date-class-for-price {
  font-size: 12px;
  white-space: nowrap;
}

.download-btn-grid {
  justify-content: flex-end;
}

.download-link {
  display: flex;
  justify-content: flex-end;

  .p-button {
    padding-left: 2px;
    padding-right: 2px;
  }
}