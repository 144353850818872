@mixin font-global-css {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@import 'theme';
@import 'common';
@import 'forms';

@import 'shared/components/header.component';
@import 'shared/components/page-header.component';
@import 'shared/components/sidebar.component';
@import 'shared/directives/search-location.directive';
@import 'shared/components/mi-grid.component';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,200;0,8..144,300;0,8..144,400;0,8..144,500;0,8..144,700;1,8..144,100;1,8..144,200;1,8..144,300;1,8..144,400;1,8..144,500;1,8..144,600;1,8..144,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
@import 'primeng';