app-header {
  background-color: $topBarBgColor;
  box-shadow: 0 7px 7px -6px rgba(0, 0, 0, .13);
  color: $topBarTextColor;

  a {
    color: $topBarTextColor;

    &:link,
    &:active,
    &:focus {
      color: $topBarTextColor;
      outline: none;
      text-decoration: none;
    }

    &.hamburger-menu {
      color: #b0bec5;
      display: block;
      font-size: 24px;
      height: 61px;
      line-height: 61px;
      padding: 0 16px;

      &:hover {
        color: $defaultThemeColor;
      }
    }
  }
}

.drop {
  height: 2.5rem;
  width: 180px;
  margin-right: 6px;

  .p-dropdown {
    height: 2.5rem;
  }
}

.country-item-value {
  img.flag {
    width: 17px;
  }
}

p-skeleton {
  width: 100%;
}

.country-item {
  display: inline-flex;
  align-items: center;

  img.flag {
    margin-right: 7px;
    width: 17px;
    // padding-top: 3px;
  }
}

.app-header {
  height: 48px;
}

.top-bar-item {
  float: left;

  &.notifications-bell {
    background-color: $defaultThemeColor;
    border: 1px solid $white;
    border-radius: 50px;
    height: 27px;
    margin: 11px 7px;
    padding: 5px 7px;
    position: relative;

    &::after {
      color: $black;
      content: '';
      display: inline-block;
      height: 60px;
      left: 55px;
      position: absolute;
      top: -16px;
      width: 1px;
    }

    .fa {
      font-size: 17px;
    }

    &:hover {
      background-color: $buttonHoverColor;
      cursor: pointer;

      .fa {
        color: $white;
      }
    }

    .new-notification-count {
      display: inline-block;
      font-size: 17px;
      line-height: 1.1;
      margin-left: 4px;
    }
  }

  &:hover {
    float: left;
  }
}

.nav-right-menu {
  .nav-notifications-icon {
    color: $topBarBgColor;
    line-height: 31px;
    padding: 0 10px;
  }

  .profile-menu-button {
    border-left: 2px solid $headerSplitBarColor;
    margin-left: 20px;

    a {
      border: 4px solid $white;
      color: $headerUserName;
      cursor: pointer;
      display: block;
      font-size: 16px;
      padding: 3px 15px;

      .user-photo {
        border: 1px solid $sideBarHoverBgColor;
        border-radius: 50%;
        display: block;
        float: left;
        height: auto;
        margin: 4px 12px 4px 4px;
        width: 39px;
      }

      .fa {
        margin-left: 8px;
      }

      &:hover {
        border-bottom: 4px solid $sideBarHoverBgColor;
      }
    }

    .ui-menuitem-link {
      padding: 10px 0;

      .fa {
        font-size: 20px;
        margin: 0 9px 0 28px;
      }

      &:hover {
        background: $sideBarHoverBgColor;
      }
    }
  }
}

.ui-menu {
  &.ui-menu-dynamic {
    display: none;
    padding: 0;
    position: absolute;
    right: 0;
    width: 223px;
    z-index: 100000;
  }

  &.ui-menuitem {
    clear: both;
    margin: 5%;
    padding: 0;
    width: 100%;
  }
}

.notification-try {
  p-overlayPanel {
    height: 350px;
    width: 350px;
  }

  .ui-overlaypanel {
    height: 350px;
    margin-top: 11px;
    top: 61px;
    width: 350px;
  }

  .ui-overlaypanel-content {
    background: $white;
    height: 350px;
    overflow: auto;
    padding: 0;
    width: 350px;

    hr {
      margin: 0;
    }

    .notification-show-more-link {
      padding: 20px;

      a {
        cursor: pointer;

        &:hover {
          color: $defaultThemeColor;
        }
      }
    }
  }
}



.header-btn .pi::before {
  font-size: 20px;
}

.first-item {
  position: absolute;
  left: 0;
}




.header-strip {
  background-color: #f5f6fa;
  height: 57px;
  left: 250px;
  padding: 8px 8px;
  padding-right: 20px;
  position: fixed;
  right: 0;
  top: 0;
  transition: left .5s;
  z-index: 997;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .header-btn {
    border-radius: 50%;
    color: #fff;
    float: left;
    font-size: 28px;
    height: 44px;
    line-height: 50px;
    text-align: center;
    transition: background-color .3s;
    width: 44px;

    i {
      color: $defaultThemeColor;
      font-size: 28px;
    }

    &:hover {
      background-color: #f2ecec;

      i {
        color: $defaultThemeColor;
      }
    }
  }

  .user-name {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #212736;
  }

  .user-role {
    opacity: .5;
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #212736;
  }

  .name {
    margin-left: 7px;
  }

  .header--menu {
    float: right;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      float: left;
      margin-left: 14px;
      position: relative;
      display: flex;
      align-items: center;

      a {
        border-radius: 50%;
        display: block;
        height: 44px;
        line-height: 44px;
        position: relative;
        text-align: center;
        transition: background-color .3s;
        width: 44px;

        img {
          border-radius: 50%;
          margin-top: 4px;
          width: 36px;
        }

        &:hover {
          background-color: #f2ecec;
        }
      }

      &.toggle-menu {
        ul {
          display: block;
        }
      }

      ul {
        animation-duration: .3s;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 3px;
        box-shadow: 0 2px 10px 0 rgba(0, 3, 6, .16);
        display: none;
        list-style-type: none;
        margin: 0;
        min-width: 250px;
        padding: 8px 12px;
        position: absolute;
        right: -18px;
        top: 55px;

        &::before {
          border-bottom-color: #e0e0e0;
          border-color: rgba(216, 216, 220, 0);
          border-width: 10px;
          margin-left: -10px;
        }

        &::after {
          border: solid transparent;
          bottom: 100%;
          content: ' ';
          height: 0;
          left: 210px;
          pointer-events: none;
          position: absolute;
          width: 0;
        }

        a {
          border-radius: 3px;
          box-sizing: border-box;
          color: #727272;
          display: block;
          line-height: 28px;
          padding: 8px 12px;
          position: relative;
          text-align: left;
          transition: background-color .3s;
          width: 100%;

          i {
            color: #727272;
            margin-right: 8px;
          }

          span {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

@keyframes fade-opacity {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fade-opacity {
  animation-name: fade-opacity;
}

.closed {
  .header-strip {
    left: 100px;
    transition: left .5;
  }
}

.change-password-input,
.user-profile {
  cursor: pointer;
}

.update-button {
  float: inline-end;
}