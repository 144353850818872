@mixin input-global-css {
  height: 18px;
  font-family: Roboto;
  font-size: 15px;
}

.p-datatable .p-paginator-bottom {
  margin-top: 1rem;
  border-width: 0;
  border-radius: 0;
  background-color: #f5f6fa;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
  padding-top: 3px;
  padding-bottom: 0;
}

.p-paginator .p-dropdown {
  margin-left: .5rem;
  margin-right: .5rem;
  height: 31px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 4px;
  width: 31px;
  height: 30px;
}

.p-datatable .p-datatable-thead>tr>th {
  background-color: $white;
  border: 0;
  box-shadow: 0 5px 20px 0 rgba(205, 226, 246, .3);
  font-weight: 500;
  font-size: 13px;
  color: #212121;
}

.p-datatable .p-sortable-column.p-highlight {
  background-color: rgba(62, 94, 255, .1);
  border: 0;
  color: #3e5eff;
}

.p-card {
  background: #fff;
  color: #333333;
  box-shadow: none;
  border-radius: 3px;

  .p-card-content {
    padding: 1rem;
  }
}

.p-dropdown {
  margin: 0 !important;
}

.p-dropdown:not(.p-disabled):hover {
  border: 1px solid #3e5eff !important;
}

// .p-checkbox .p-checkbox-box {
//   width: 10px;
//   height: 10px;
//   border-radius: 0;
// }

.p-dialog {
  border-radius: 16px;
  width: 391px;
}

.p-dialog {
  .p-dialog-header {
    background-color: #fff;
    border-bottom: 0;
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
    padding-top: 25px;
    border-top-right-radius: 13px;
    border-top-left-radius: 13px;

    .p-dialog-title {
      font-family: Roboto;
      font-size: 21px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #212736;
    }
  }

  .p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 0;
  }

  .p-dialog-header-icons {
    display: none !important;
  }
}

.p-confirm-dialog .p-dialog-content {
  padding: 10px 30px 10px 30px;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.33;
  text-align: center;
  color: #6d7175;
  @include font-global-css;
}

.p-dialog .p-dialog-footer {
  border-bottom-right-radius: 13px;
  border-bottom-left-radius: 13px;
  border-top: 0;
  padding: 20px 15px 25px 20px;
  text-align: center;
}

.p-dialog .p-dialog-footer button {
  width: 165px;
}

.p-button .p-button-icon-left {
  margin-bottom: 2px;
}

.p-button .p-button-icon-right {
  margin-bottom: 2px;
}

.p-button {
  background: #3e5eff;
  border: 1px solid #3e5eff;
  height: 2.5rem;
  justify-content: center;
}

.p-button:enabled:hover {
  background: #3e5eff;
  border-color: #3e5eff;
}

.p-button-label {
  font-weight: 500;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #727588;
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #212736;
}

.p-breadcrumb {
  font-family: Roboto;
  font-size: 12px;
  color: #727588;
  border: 0;
  padding-top: 25px;
  padding-left: 20px;
  @include font-global-css;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0;
  color: #727588;
  font-size: 10px;
}

// For sidebar
.p-tabview {
  & .p-tabview-panels {
    border: 0;
    padding: .571rem 1rem;
  }

  & .p-tabview-nav {
    margin-left: 28px;
    border-bottom: solid 1px #e0e0e0;
    width: 95%;

    & li {
      border-bottom: solid 1px #e0e0e0;

      & .p-tabview-nav-link {
        color: #404566;
        background: none;
        border-color: #fff;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }

    & li.p-highlight {
      & .p-tabview-nav-link {
        color: #3e5eff;
        background: none;
        border-color: #fff;
        border-bottom: solid 1px #3e5eff;
        font-family: Roboto;
      }
    }
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

// autocomplete
.p-autocomplete:not(.p-disabled):hover {
  // border: 1px solid #3e5eff !important;
}

// autocomplete multiple
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border: 1px solid #3e5eff !important;
}

.p-autocomplete {
  width: 100%;
  border-radius: 5px;

  .p-inputtext:enabled:hover {
    border-color: #3e5eff;
  }

  & .p-autocomplete-multiple-container {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 5px;
    width: 100%;

    & .p-autocomplete-input-token {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border: 0;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none;
}

.p-chips {
  .p-chips-multiple-container .p-chips-token {
    .p-chips-token-icon {
      margin-left: 0;
    }

    width: auto;
    height: 30px;
    padding: 5px 9px 5px 10px;
    border-radius: 5px;
    background-color: #e8ebf1;

    span {
      color: #212736;
    }

    span:first {
      height: 16px;
      font-family: Roboto;
      font-size: 14px;
      @include input-global-css();
    }

    span:last-child {
      padding-bottom: 1px;
    }
  }

  .p-chips-multiple-container:not(.p-disabled).p-focus {
    box-shadow: none;
  }
}

.p-inputtext {
  padding: 10px;
}

.p-inputtext:enabled:hover {
  border-color: #3e5eff;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.p-inputnumber-input {
  border-radius: 5px;
}

.p-dialog-content {
  overflow-y: visible !important;
}

.p-button.p-button-outlined {
  color: #3e5eff;
}

.p-button.confirmDialog-cancel-btn {
  color: black;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  background: #e8ebf1;
  color: #333333;
  padding: 7px 10px;
  border-radius: 5px;
}

//radio button css style
.p-radiobutton .p-radiobutton-box.p-highlight {
  background-color: transparent;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background-color: transparent;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  background-color: #456cff;
}

.p-radiobutton-label {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212736;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #fff;
}

.p-fileupload .p-fileupload-content {
  padding: 0;
}

.p-fluid .p-inputnumber .p-inputnumber-input {
  padding-left: 15px;
}

.p-timeline-event-opposite,
.p-timeline-event-content {
  flex: 0 !important;
  white-space: nowrap;
}

.p-timeline .p-timeline-event-marker {
  background-color: #d8d8d8;
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 0;
  border: dashed 1px #e4dddd;
  background: transparent;

}

.p-radiobutton-label {
  margin-left: 0;
  padding-left: .5rem;
}

.calendar-with-clear {
  p-calendar.p-calendar-clearable .p-calendar-clear-icon {
    right: 2.5rem
  }
}

.p-checkbox-label {
  display: flex;
  align-items: center;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: #3e5eff;
}

.change-password {
  .p-dialog-header-icons {
    display: block !important;
    position: absolute;
    right: 16px;
    top: 10px;
  }

  .p-dialog-content {
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
  }
}

.exportButton {
  .p-button {
    color: #3e5eff;
    background: white;
    border: 1px solid #3e5eff;
    height: 2.5rem;
    justify-content: center;
  }
}