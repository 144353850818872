* {
  box-sizing: border-box;
}

body {
  background-color: $bodyBgColor;
  color: $BodyTextColor;
  font-family: $fontFamily;
  font-size: $fontSize;
  margin: 0;
  padding: 0;
}

.pi-sort-amount-up-alt:before,
.pi-sort-amount-down:before {
  color: #3e5eff;
  font-size: 10px;
}

.pi-sort-alt:before {
  font-size: 10px;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
  background-color: rgba(62, 94, 255, .1);
  border: 0;
  color: #3e5eff;
}

.p-datatable .p-datatable-tbody>tr>td {
  border: 1px solid #f4f5fa;
  border-style: none none solid none;
  font-size: 15px;
  font-family: Roboto;
  font-weight: normal;
  max-width: 150px;
}

.p-panelmenu .p-panelmenu-header>a {
  background-color: #f5f6fa;
  border: 0 solid #c8c8c8;
  font-weight: 500;
}

.p-panelmenu .p-panelmenu-header.p-highlight>a {
  background-color: rgba(62, 94, 255, .1);
  border: 0;
  color: #3e5eff;
}

.p-panelmenu .p-panelmenu-header.p-highlight+.p-toggleable-content .p-menuitem-link-active {
  background-color: rgba(62, 94, 255, .1);
  border: 0;
  color: #3e5eff;
}

.p-panelmenu .p-panelmenu-header.p-highlight+.p-toggleable-content .p-menuitem-link-active .p-menuitem-text {
  color: #3e5eff;
  font-weight: 500;
}

.p-panelmenu .p-panelmenu-content {
  border: 0;
}

.pi:before,
.fa:before {
  padding: 7px;
  font-size: 12px;
}

.p-panelmenu .p-panelmenu-header>a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0 #020f19;
}

.p-datatable .p-sortable-column:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0 #020f19;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #3e5eff;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: white;
}

.p-dropdown .p-dropdown-label {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  align-self: center;
  align-items: center;
  display: flex;
}

.form-field input[type='text'] {
  border-radius: 5px;
  border: solid 1px #b9bcce;
  background-color: #fff;
  padding: 10px;
  margin: 8px 0 0;
  height: 40px;
}

.form-field label {
  margin: 8px 4px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #616161;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  color: #3e5eff;
  background-color: rgba(62, 94, 255, .1);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  background-color: #f5f6fa;
  color: #404566;
  font-size: 13px;
  padding-left: 30px;
  font-weight: 400;
  display: block;
}

.p-calendar-w-btn .p-datepicker-trigger {
  background: transparent;
  color: #a6a6a6;
  border: 1px solid #a6a6a6;
  border-left: 0;
}

.p-calendar-w-btn .p-inputtext {
  border-right: 0;
}

.p-component {
  font-family: 'Roboto';
  font-size: 15px;
}

button {
  background-color: #3e5eff;
  color: white;
  // margin: 5px;
  padding: 8px 20px;
  border-radius: 3px;
  border: 0;
}

.p-dropdown {
  width: 100%;
  margin: 8px 0;
  height: 2.5rem;
  border-radius: 5px;
  border: solid 1px #b9bcce;
}

.p-dropdown:placeholder {
  font-weight: 700;
}

.inp {
  width: 350px;
  height: 2.5rem;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  border: solid 1px #b9bcce;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 2px 0;
  border-radius: 5px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  border-radius: 5px;
  margin: 2px 0;
}

.p-autocomplete-panel {
  padding: 5px;
}

.p-dropdown-items-wrapper {
  padding: 5px;
}

.p-multiselect {
  width: 100%;
  height: 2.5rem;
  padding: 0 10px;
  border-radius: 5px;
  border: solid 1px #b9bcce;
  // min-width: 200px;
}

.p-multiselect .p-multiselect-label {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  align-self: center;
}

.p-fluid .p-multiselect {
  align-items: center;
}

.text-center {
  text-align: center;
}

.mrg-top-10 {
  margin-top: 10px;
}

.image-code {
  background: url('/assets/images/temp/sprite.png') 0 0;
}

.image-rating-width {
  height: 25px;
  width: 25px;
}

.rating1 {
  background-position: -69px -38px;
}

.rating2 {
  background-position: -119px -38px;
}

.rating3 {
  background-position: -168px -38px;
}

.rating4 {
  background-position: -219px -38px;
}

.rating5 {
  background-position: -269px -38px;
}

.track-driver {
  cursor: pointer;
  float: right;

  &:hover {
    color: $defaultThemeColor;
  }
}

a {
  color: $linkColor;

  &:link,
  &:active,
  &:focus {
    color: $linkColor;
    outline: none;
    text-decoration: none;
  }

  &:hover {
    color: $stateFocusBgColor;
    outline: none;
    text-decoration: none;
  }
}

.main-panel {
  background-color: $mainContentBgColor;
}

.page-header {
  background-color: $pageHeaderBgColor;
}

.page-buttons {
  button {
    float: right;
    margin: 0 0 0 8px;
  }
}

.exportButton {
  button {
    min-width: 110px;
  }
}

.ql-editor {
  background: $white;
}

p-tabmenu {
  .ui-widget-content {
    border: 0 none;
  }
}

ul {
  &.ui-widget-header {
    border: 0 none;

    .ui-tabmenuitem {
      a {
        color: $inputTextColor;
      }

      &.ui-state-active {
        a {
          color: $gridHeaderTextColor;
        }
      }
    }
  }
}

.ui-tabmenu-nav {
  background: $stateDefaultBgColor;
  border: 0;

  li {
    &.ui-tabmenuitem {
      background-color: $stateDefaultBgColor;

      &:not(.ui-state-active) {
        background-color: $sideBarHoverBgColor;

        &:hover {
          background-color: $secondaryThemeColor;
          border-color: $stateDefaultBorderColor;

          a {
            color: $sidebarIconColor;
          }
        }

        a {
          color: $sidebarIconColor;
        }
      }
    }
  }
}

.panel-div {
  background-color: $panelDivBgColor;
  padding: 8px;
}

.panel-div-top-info {
  padding: 0 0 8px;

  .left-info {
    text-align: left;
  }

  .right-info {
    text-align: right;
  }
}

h1 {
  &.page-title {
    font-size: 18px;
  }
}

.bread-crumbs {
  float: none;
  padding: 22px 17px 0;

  li {
    display: inline-block;
  }
}

.bread-item {
  display: inline-block;
  list-style-type: none;
}

.ui-panel {
  .ui-widget-header {
    font-size: $headerFontSize;
    font-weight: bold;
  }

  .ui-panel-titlebar {
    padding: 8px 16px;
  }

  .ui-panel-content {
    background: none;
    border: 0;
    padding: 8px 16px;
  }
}

.ui-button {
  &.ui-state-default {
    background: $buttonDefaultBgColor;
    border: 1px solid $buttonDefaultBgColor;
    color: $buttonDefaultTextColor;

    &:hover {
      background: $buttonHoverColor;
      border: 1px solid $buttonHoverColor;
    }
  }

  &.negative-button {
    background-color: $negativeButtonColor;
    border: 1px solid $negativeButtonColor;

    &:hover {
      background-color: $negativeButtonHoverColor;
      border: 1px solid $negativeButtonHoverColor;
    }
  }

  &.ui-button-danger {
    &:enabled {
      &:hover {
        background-color: $stateFocusBgColor;
      }
    }

    &:focus {
      background-color: $stateFocusBgColor;
    }
  }
}

.ui-button-text-only {
  .ui-button-text {
    padding: 8px 16px;
  }
}

.ui-button-text-icon-left {
  font-size: 18px;

  .ui-button-text {
    font-size: 13px;
    padding: 8px 10px 8px 36px;
  }
}

.ui-inputtext {
  &.ui-state-default {
    background: $inputFieldsBgColor;
    border: 1px solid $inputFieldBorderColor;
    color: $contentTextColor;
    padding: 12px 16px;
  }

  &:enabled {
    &:hover {
      border: 1px solid $inputFieldBorderColor;
      box-shadow: none;
    }

    &:focus {
      border: 1px solid $inputFieldBorderColor;
      box-shadow: none;
    }
  }
}

.ui-growl-item-container {
  &.ui-state-highlight {
    &.ui-growl-message-success {
      background-color: $growlMessageSuccessColor;
      border-color: $growlMessageSuccessColor;
    }

    &.ui-growl-message-error {
      background-color: $growlMessageErrorColor;
      border-color: $growlMessageErrorColor;
    }
  }
}

.partner-box {
  background: $white;
  border: 1px solid $inputFieldBorderColor;
  color: $headerTextColor;
  margin-bottom: 20px;
}

.out-partner-info {
  cursor: pointer;
  text-align: right;
}

.lang-right-to-left {

  .form-label,
  a,
  .ui-panel-titlebar,
  p {
    direction: rtl;
  }

  input {
    &[type='text'] {
      direction: rtl;
    }
  }

  .ui-panelmenu {
    .ui-menuitem-icon {
      &.fa {
        margin-left: .5em;
      }
    }
  }
}

.assign {
  .ui-inputtext {
    &.ui-state-default {
      height: 32px;
      padding: 0 10px;
      width: 100%;
    }
  }

  .ui-autocomplete {
    width: 100%;

    .ui-autocomplete-dropdown {
      background-color: $bodyBgColor;
      border: 0 none;
      color: $defaultThemeColor;
      height: 29px;
      position: absolute;
      right: 1px;
      top: 9px;
      vertical-align: top;
      width: 22px;
    }
  }

  &.search-driver {
    .ui-autocomplete-dropdown {
      background-color: $bodyBgColor;
      border: 0 none;
      color: $defaultThemeColor;
      height: 29px;
      position: absolute;
      right: 1px;
      top: 1px;
      vertical-align: top;
      width: 22px;
    }
  }
}

.web-link {
  color: $stateDefaultTextColor;
}

.caps {
  text-transform: uppercase;
}

.map {
  height: 100%;
  width: 100%;
}

.main-container {
  margin-left: 250px;
  padding-top: 60px;
  transition: margin-left .5s;
  background: white;

  .main-content {
    padding: 16px;
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
  }
}

.dashboard-main-container {
  margin-left: 250px;
  padding-top: 60px;
  transition: margin-left .5s;

  .main-content {
    padding: 16px;
    padding-left: 0;
    padding-right: 0;
  }
}

.dashboard-view-main-container {
  margin-left: 250px;
  padding-top: 60px;
  transition: margin-left .5s;
  background: white;

  .main-content {
    padding: 16px;
    padding-left: 0;
    padding-right: 0;
  }
}

.sidebar {
  left: 0;
  transition: left .5s;
  width: 250px;
}

.eclipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.eclipse-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}


.closed {
  .sidebar {
    left: -150px;
    transition: left .5s;
  }

  .company-logo {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .p-panelmenu-icon {
    order: 0;
  }

  .bread-crumbs {
    padding-left: 117px;
    transition: padding-left .5s;
  }

  .p-panelmenu .p-menuitem-text {
    display: none;
  }

  .p-panelmenu .p-panelmenu-header>a {
    display: flex;
    justify-content: flex-end;
  }


  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    display: flex;
    justify-content: flex-end;
  }

  .main-panel {
    width: 100%;
  }

  .dashboard-main-container {
    margin-left: 0;
    padding-top: 60px;
    transition: margin-left .4s;

    .main-content {
      padding: 16px;
      padding-left: 100px;
      padding-right: 0;
    }

  }

  .main-container {
    margin-left: 0;
    padding-top: 60px;
    transition: margin-left .4s;

    .main-content {
      padding: 16px;
      padding-left: 100px;
      padding-right: 0;
    }
  }

  .dashboard-view-main-container {
    margin-left: 0;
    padding-top: 60px;
    transition: margin-left .4s;

    .main-content {
      padding: 16px;
      padding-left: 100px;
    }
  }
}

.mi-pie-chart {
  display: inline-block;
  height: auto;
  width: 250px;
}

.mi-bar-chart {
  display: inline-block;
  height: 271px;
  width: 60%;
}

.flexbox {
  flex: 1;
  height: 233px;
}

.error {
  color: $defaultThemeColor;
  font-size: 16px;
}

.details {
  display: flex;
}

.top-padding {
  padding-top: 3%;
}

.document {
  cursor: pointer;
}

.loader-hidden {
  visibility: hidden;
}

.loader-page-center-overlay {
  background-color: $loaderBgColor;
  bottom: 0;
  height: 70px;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 88px;
  z-index: 500000;
}

.info-label {
  color: #3d4246;
  display: inline-block;
  font-weight: bold;
}

.info-data {
  display: inline-block;
}

.device-height {
  min-height: 100vh;
}

.add-edit-panel {
  padding: 30px 20px 20px;
}

.ui-button-icon-only {
  .ui-button-text {
    margin-top: -2px;
  }
}

.ui-autocomplete {
  .ui-autocomplete-dropdown {
    height: 30px;
    top: -1px;
    vertical-align: inherit;
  }
}

.user-pic {
  margin: 5% 15%;
  width: 70%;
}

p-calendar {
  &.short-input {
    .ui-calendar {
      input {
        &[type='text'] {
          width: 95%;
        }
      }
    }

    .ui-button {
      &.ui-state-default {
        right: 4%;
      }
    }
  }

  .ui-button {
    &.ui-state-default {
      background-color: $inputFieldsBgColor;
      border: 1px solid $inputFieldBorderColor;
      border-left: 0 none;
      color: $calendarIconColor;
      height: 30px;
      margin: 8px 1px;
      position: absolute;
      right: 0;
    }
  }

  input {
    width: 100%;
  }
}

.ui-spinner {
  .ui-spinner-up {
    font-size: 12px;
    height: 14px;
    margin: 8px 0 0;
  }

  .ui-spinner-down {
    font-size: 12px;
    height: 14px;
    margin: 0;
    top: 24px;
  }
}

.inner-container {
  padding: 0 15px;
}

.gridparent {
  margin: 0 6px;
  padding: 0;
}

.img-doc {
  max-width: 60px;
}

.active-box {
  background: $white;
  box-shadow: 0 3px 5px -2px rgba(0, 0, 0, .33);
  color: $activeBoxTextColor;
  margin-right: 20px;
  padding: 7px 15px;
  text-align: left;

  .special-count {
    float: right;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.form-buttons {
  &.corporate-button-bar {
    margin-top: 60px;
  }

  button {
    &.destination-add-full-btn {
      margin: 0;
      width: 100%;
    }
  }
}

.reset-btn {
  background-color: $white;
  border: 1px solid $inputFieldBorderColor;
  border-radius: 4px;
  color: $defaultThemeColor;
  cursor: pointer;
  float: left;
  margin-top: 7px;
  padding: 6px 10px;
}

p-dropdown {
  .ui-dropdown-items {
    .ui-state-highlight {
      background: $dropdownBgColor;
      border-color: $dropdownBorderColor;
      color: $white;
    }
  }

  .ui-dropdown {
    &:not(.ui-state-disabled) {
      &:active {
        border-color: $inputFieldBorderColor;
      }

      &:hover {
        border-color: $inputFieldBorderColor;
      }
    }

    .ui-dropdown-trigger {
      .fa {
        color: $defaultThemeColor;
      }
    }
  }

  .ui-inputtext {
    &:focus {
      border-color: $inputFieldBorderColor;
      box-shadow: none;
    }
  }
}

.ui-chkbox-box,
.ui-radiobutton-box {
  &.ui-state-default {
    background: $checkboxBackgroundColor;
    border-color: $checkboxActiveBorderColor;
    color: $checkboxCheckColor;
  }

  &:not(.ui-state-disabled) {
    &:not(.ui-state-active) {
      &:hover {
        background-color: $checkboxBackgroundColor;
        border-color: $checkboxActiveBorderColor;
      }
    }
  }

  &.ui-state-active {
    border-color: $checkboxActiveBorderColor;
  }
}

.form-field {
  .asterisk {
    color: $growlMessageErrorColor;
  }

  .employee-input {
    input {
      &[type='text'] {
        width: 100%;
      }
    }
  }

  .ui-dropdown-panel {
    input {
      &[type='text'] {
        width: 100%;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .ui-dropdown-filter-container {
      width: 100%;

      .fa {
        position: absolute;
        right: 21px;
        top: 21px;
      }
    }

    .ui-dropdown-item {
      &:not(.ui-state-highlight) {
        &:hover {
          background-color: $secondaryThemeColor;
          border-color: $secondaryThemeColor;
        }
      }
    }
  }
}

.delete-bin {
  &.fa {
    cursor: pointer;
    float: right;
    font-size: 16px;

    &:hover {
      color: $defaultThemeColor;
    }
  }
}

.document-container {
  width: 100%;
}

.ui-datepicker {
  .ui-datepicker-calendar {
    td {
      &:not(.ui-state-disabled) {
        a {
          &.ui-state-highlight {
            background-color: $secondaryThemeColor;
            border-color: $secondaryThemeColor;
            color: $white;
          }

          &.ui-state-active {
            background-color: $defaultThemeColor;
            border-color: $defaultThemeColor;
            color: $white;
          }

          &:hover {
            background-color: $defaultThemeColor;
            border-color: $defaultThemeColor;
          }
        }
      }
    }
  }
}

.scroll-panel {
  overflow: scroll;
  padding: 0 0 16px;
}

.box-bottom-border {
  border-bottom: 1px solid $sidebarIconColor;
}

.mt-10 {
  margin-top: 10px;
}

.step-tab {
  .ui-steps-item {
    &.ui-state-highlight {
      background-color: $secondaryThemeColor;

      a {
        color: $white;

        &:focus {
          color: $white;
        }
      }

      &:hover {
        a {
          color: $white;
        }
      }
    }

    &.ui-state-disabled {
      a {
        color: $secondaryThemeColor;
      }
    }
  }
}

p-multiselect {
  .ui-multiselect {
    &:not(.ui-state-disabled) {
      &:hover {
        border-color: $inputFieldBorderColor;
      }
    }

    .ui-multiselect-trigger {
      border: 0 none;
      color: $defaultThemeColor;
    }
  }
}

p-selectbutton {
  .ui-selectbutton {
    .ui-button {
      &:not(.ui-state-disabled) {
        &:not(.ui-state-active) {
          &:hover {
            background-color: $secondaryThemeColor;
            border-color: $secondaryThemeColor;
          }
        }
      }
    }
  }
}

p-chips {
  .ui-chips {
    .ui-inputtext {
      &:hover {
        border-color: $inputFieldBorderColor;
      }

      .ui-chips-token {
        &.ui-state-highlight {
          background-color: $defaultThemeColor;
          border-color: $defaultThemeColor;
        }
      }

      .ui-chips-input-token {
        input {
          &[type='text'] {
            visibility: hidden;
          }
        }
      }
    }
  }
}

.right-align {
  padding-right: 3%;
  text-align: right;
}

.same-height-div {
  display: flex;

  .beside-div-same-height {
    background: $white;
    flex: 1;
    margin: 0 .5em .5em;
    padding: 0;
  }
}

p-dialog {
  .ui-dialog {
    &.ui-corner-all {
      border: 0;
    }

    .ui-dialog-titlebar {
      background: $secondaryThemeColor;
      border: 0;
      color: $white;
      font-size: 16px;
      padding: 1em 1.1em;
      position: relative;

      .ui-dialog-titlebar-close {
        border: 0;
        color: $white;

        &:hover {
          background-color: $secondaryThemeColor;
          border: 0;
          color: $white;
        }
      }
    }

    .ui-dialog-footer {
      overflow: hidden;
      padding: .8em;
    }
  }
}

.access-checkbox-div {
  display: inline-block;
  padding: 5px;
}

.access-editbtn-div {
  display: inline-block;
  padding: 5px;
}

.department-autocomplete {
  margin-top: 4%;

  .ui-inputtext {
    input {
      &[type='text'] {
        border: 0 none;
      }
    }
  }

  .ui-state-default {
    padding: 10px;
  }

  .ui-autocomplete-panel {
    width: 96%;
  }

  ul {
    width: 96%;

    li {
      font-size: 14px;
    }
  }
}

.faq-form {
  margin-bottom: 30px;
}

.faq-panel-marin {
  margin-bottom: 2%;

  .content {
    padding-bottom: 2%;
    text-align: justify;
  }

  .footer {
    text-align: right;
  }

  p-header {
    font-size: 14px;
    padding: 0;
  }

  &.ui-panel {
    padding: 0;

    .ui-panel-titlebar {
      background: $secondaryThemeColor;
      border: 0;
      color: $sidebarIconColor;
    }

    .ui-panel-titlebar-icon {
      color: $secondaryThemeColor;
      font-size: 13px;
      margin-top: 5px;

      span {
        margin-top: 3px;
      }
    }

    .ui-panel-content {
      padding: 16px;
    }

    .cursor-pointer {
      cursor: pointer;
    }
  }
}

.term-conditions {
  padding: 45px;

  h3 {
    color: $sideBarHoverBgColor;
    font-size: 17px;
    margin: 0;
  }
}

.ql-align-justify {
  color: $secondaryThemeColor;
  font-size: 15px;
  margin: 10px 0 45px;
  text-align: justify;
}

.driverassigndropdown {
  .ui-autocomplete {
    width: 100%;

    input {
      &[type='text'] {
        border: 1px solid #b9bcce;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        height: 32px;
        margin: 7px 0;
        padding: 0 2%;
        width: 94%;
      }
    }

    .ui-autocomplete-dropdown {
      border: 0 none;
      position: absolute;
      right: 2px;
      top: 8px;
      vertical-align: top;
    }
  }

  .driver-name {
    display: inline-block;
    margin: 10px 10px 0 0;
    text-align: left;
    width: 23%;
  }

  .driver-ref-no {
    display: inline-block;
    margin: 10px 10px 0 0;
    text-align: center;
    width: 35%;
  }

  .driver-eta {
    display: inline-block;
    margin: 10px 10px 0 0;
    text-align: right;
    width: 26%;
  }
}

.trip-details {
  .pickup-circle {
    color: $mapPickup;
  }

  .drop-circle {
    color: $mapDrop;
  }

  .one-line-block {
    float: right;
    margin-left: 20px;
  }

  .ui-fileupload-buttonbar {
    padding: 0 0 0 10px;
  }
}

.assign-btn {
  float: right;
  margin: 0 15px 9px 0;
}

.attached-docs {
  fieldset {
    margin: 20px 0;
  }

  ul {
    margin: 0;
    padding: 0;

    .panel-div {
      padding: 0 15px;
    }
  }
}

.destination-box {
  background: $destinationBox;
  border-bottom: 1px solid $secondaryThemeColor;
  overflow: auto;
}

.forgot-password-link {
  cursor: pointer;
  float: right;

  margin-top: 6px;
  width: 122px;
  height: 18px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e5eff !important;
}

.rental {
  .destination-box {
    background: $destinationBox;
    border-bottom: 1px solid $secondaryThemeColor;
    display: block;
    min-height: 186px;
    overflow: auto;
  }
}

.pricing-bundle {
  min-height: 196px;
}

.fa-trash {
  cursor: pointer;
}

.fa-pencil {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.at-bottom {
  bottom: 0;
  position: absolute;
}

.assign-search-input-layout {
  margin: 15px 0 10px;
}

.type-of-passenger {
  margin-top: 10px;
}

.driver-eta {
  float: right;
}

.m-20 {
  margin: 20px;
}

.emergency-note {
  color: $defaultThemeColor;
}

.route-map-height {
  height: 446px;
}

.disabled-row {
  background: $stateDefaultBorderColor;
}

.enabled-row {
  background: $white;
}

.table-custom-component-wrapper {
  padding-bottom: 20px;
}

.searchbar {
  .p-input-icon-right {
    .pi-search::before {
      font-size: 16px;
    }
  }

  input {
    width: 100% !important;
    min-width: 340px;
    height: 2.5rem;
    padding: 5px 10px 6px;
    border: solid 1px #b9bcce;

    @media screen and (max-width: 1199px) {
      min-width: 140px;
    }
  }

  // margin: 20px 20px 40px;
  border-radius: 5px;
  // border: solid 1px #b9bcce;
  background-color: #fff;
}

.bulk-dropdown {
  div {
    height: 2.5rem;
  }
}

.statusDropdown {
  width: 190px;
}

input {
  width: 100%;
  height: 2.5rem;
  box-shadow: none;
  // border-radius: 3px;
  // font-size: 18px;
  padding: 10px;
}

.input-text {
  // margin: 8px 0 0;
  padding: 11px 0 11px 10px;
  border-radius: 5px;
  background-color: #fff;

  &:focus {
    border: solid 1px #3e5eff;
  }
}

.label-text-main {
  height: 2.5rem;
  width: inherit;
  margin: 0 5px 24px 0;

  span {
    color: #212736;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    @include font-global-css;
  }
}

.label-text {
  height: 1rem;
  width: inherit;
  margin: 0 5px 8px 0;

  label {
    color: #616161;
    font-family: Roboto;
    font-size: 15px;
    width: inherit;
    @include font-global-css;
  }
}

.pri-btn-dialog {
  background-color: #3e5eff;
  color: white;
  margin: 5px;
  padding: 8px 30px;
  height: 2.5rem;
  border-radius: 3px;
  border: 0;
  width: 165px;
}

input {
  height: 2.5rem;
  font-size: 1rem;
  color: #333333;
  border-radius: 5px;
  border: 1px solid #a6a6a6;


  &:focus {
    box-shadow: none;
    outline: none;
  }

}

.country-save-next-button-position {
  position: relative;
  float: right;
  width: inherit;
}

.bottom-btn {
  margin-bottom: 30px;

  .p-button {
    font-weight: 500;
  }
}

.top-btn {
  .p-button {
    font-weight: 500;
  }
}

.cancel-save-button {
  background-color: #f5f6fa;
  padding: 2rem 0;
  padding-right: 35px;
  margin-bottom: -1rem;
  margin-right: -1rem;
}

.p-panel .p-panel-header {
  background: #eff2f6;
}

.p-panel.p-panel-toggleable .p-panel-header {
  height: 50px;
}

//Rushikesh
.p-inputgroup {
  height: 2.5rem;

  .p-inputwrapper>.p-component {
    width: 100%;
    border: 0;
  }
}

.p-inputgroup-addon {
  background: #fff;
  padding-top: 0;
  padding-bottom: 0;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup>.p-inputwrapper:last-child>.p-component,
.p-inputgroup>.p-inputwrapper:last-child>.p-component>.p-inputtext {
  border-left: 0;
}

.p-inputgroup .p-inputwrapper>.p-component {
  height: 33px;
}

.p-divider.p-divider-vertical {
  margin: 0%;
  padding: 0%;
  margin-top: 7px;
}

.p-divider-vertical {
  min-height: 65% !important;
}

.required-star {
  color: red;
}

.small-label {
  width: 10rem;
  height: 1rem;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #606060;
}

label {
  width: 15rem;
  height: 1rem;
  font-family: Roboto;
  font-size: 15px;
  @include font-global-css;
  color: #606060;
}

.overlay-panel {
  width: 220px !important;
  padding-left: 0;
  padding-right: 0;
}

.w-100 {
  width: 100%;
}

.overflowLabel-class {
  white-space: nowrap;
  overflow: visible;
}

.input-block-search {
  display: flex;
}

.upload-product-type {
  .p-radiobutton-label {
    width: auto;
  }
}

.sku-button-div {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
}

.sku-info {
  font-size: 13px;
  color: gray;
}

.edit-sku-table {
  cursor: pointer;
}

.edit-sku-table::before {
  font-size: 10px !important;
}

.tabel-body-value {
  display: flex;
}

.green-class {
  color: #61aa14;
  // pointer-events: none;
}

.red-class {
  color: #e60000;
}

.upload-file-box-disabled-text {
  a {
    opacity: 0.6;
    color: gray !important;
    pointer-events: none;
  }
}

.switch-text-available {
  font-weight: bold;
  font-size: medium;
  margin: 5px 5px 5px 10px;
}